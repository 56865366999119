// eslint-disable-next-line import/no-extraneous-dependencies
import { Time as CommonUtilsTime } from 'common-utils/interfaces';

/**
 * Internally used type for now
 */
export class Time implements CommonUtilsTime {
  /**
   * Internal representation is a Date object
   *
   * @private
   */
  private date: Date;

  /**
   * Constructor takes a date
   *
   * @param date
   */
  constructor(date) { this.date = date; }

  to24HourTime(): string { return `${this.date.getHours()}:${this.date.getMinutes()}`; }

  getHours(): number { return this.date.getHours(); }

  setHours(hours: number): void { this.date.setHours(hours); }

  setMinutes(minutes: number): void { this.date.setMinutes(minutes); }

  getMinutes(): number { return this.date.getMinutes(); }

  getTime(): number { return this.date.getTime(); }

  set(time: CommonUtilsTime): void { this.date.setTime(time.getTime()); }
}
